import axios from 'axios'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from '@/App'
import { createRouter, createWebHashHistory } from 'vue-router'
import { routes } from '@/router'
import VueCookies from 'vue3-cookies'
import lang from '@/lang'
import ElementPlus from 'element-plus'
import '@/styles/app.scss'
import '@/filters/globalFilter'
import globalFilter from '@/filters/globalFilter'
import globalMixin from '@/mixins/globalMixin'
import PermissionDirective from '@/directives/permission'
import { useConfigStore } from '@/stores/configStore'
import { useSecurityStore } from '@/stores/securityStore'
import { useOidcStore } from '@/stores/oidcStore'
import { getConfig } from '@/common/config'
import * as securityHelper from '@/common/securityHelper'
import * as oidcHelper from '@/common/oidc/oidcHelper'

const main = () => {
  const securityStore = useSecurityStore()

  const router = createRouter({
    root: getConfig().baseUrl,
    history: createWebHashHistory(),
    routes: routes
  })

  let authMode = getConfig().authMode

  if (authMode === 'oidc') {
    router.beforeEach((to) => {
      const oidcStore = useOidcStore()
      document.title = lang.i18n.global.t(to.meta.title)

      // for oidc
      if (
        to.path === '/oidc-callback' ||
        to.path === '/oidc-silent-callback' ||
        to.path === '/403' ||
        to.path === '/no-permission' ||
        to.path === '/404' ||
        to.path === '/error'
      ) {
        return true
      }

      return oidcHelper
        .getAccessToken()
        .then(() => {
          // handle menu and permission
          if (!securityHelper.hasAnyPermission()) {
            return '/no-permission'
          } else if (securityHelper.hasMenuAccess(to)) {
            return true
          } else {
            return '/403'
          }
        })
        .catch((err) => {
          console.log('get access token error in main, start signin action.', err)
          oidcStore
            .oidcSignin({
              currentRoute: to.path
            })
            .catch((err) => {
              console.log('dispatch signin error! redirecting to Error View', err)
              router.push({
                name: 'error',
                params: { data: err }
              })
              return true
            })
        })
        .catch(() => {
          return '/error'
        })
    })
  }

  let i18n = lang.i18n

  const app = createApp(App)
  app.use(ElementPlus, {})

  app.use(i18n)
  localStorage.removeItem('missingKeys') // clear missing keys

  app.use(VueCookies)

  // pinia
  const pinia = createPinia()
  pinia.use(piniaPluginPersistedstate)

  app.use(pinia)
  app.use(router)
  // configure mixins
  app.mixin(globalMixin)
  // // configure filters
  app.config.globalProperties.$filters = globalFilter
  // directives
  app.directive('permission', PermissionDirective)

  app.mount('#app')

  const configStore = useConfigStore()
  // startup scripts
  // handle favicon
  let faviconUrl = configStore.getterCurrentConfig.faviconUrl
  let favicon = document.getElementById('favicon')
  console.log('setting favicon...', faviconUrl, favicon)
  if (faviconUrl && favicon) {
    favicon.href = faviconUrl
    console.log('favicon set.', faviconUrl)
  }

  // handle default theme
  // let defaultTheme = configStore.getterCurrentConfig.defaultTheme
  // if (defaultTheme) {
  //   uiStore.setTheme(defaultTheme).then(() => {
  //     console.log('default theme set.', defaultTheme)
  //   })
  // }
}

axios.get('config.json').then((resp) => {
  console.log('Application config loaded.', resp.data)

  // create a dummy app with pinia in order to persist the config
  const pinia = createPinia()
  pinia.use(piniaPluginPersistedstate)
  const app = createApp(null)
  app.use(pinia)
  const configStore = useConfigStore()
  configStore.setCurrentConfig(resp.data).then(() => {
    main()
  })
})
