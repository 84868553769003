import { axiosInstance } from './common/axiosService'

export let getMenus = () => {
  return axiosInstance
    .get('security/menus')
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.error('getMenus error', error)

      return Promise.reject(error)
    })
}

export let getPermissions = () => {
  return axiosInstance
    .get('security/permissions')
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.error('getPermissions error', error)

      return Promise.reject(error)
    })
}
