import { useUiStore } from '@/stores/uiStore'
import { useSecurityStore } from '@/stores/securityStore'
import { useOidcStore } from '@/stores/oidcStore'

// avoid duplicated silent sign in
let isSilentSignin = false

// private methods
let calculateExpirationTime = (user) => {
  console.log('oidcHelper:calculateExpirationTime start to calculate token expiration in minutes', user)

  if (!user) {
    console.log('oidcHelper:calculateExpirationTime no user found, return.')
    return -1
  }

  var mins = Math.floor((user.expires_at - Date.now() / 1000) / 60)

  console.log('oidcHelper:calculateExpirationTime expire in mins:', user.expires_at, Date.now(), mins)

  return mins
}

export let getAccessToken = () => {
  const uiStore = useUiStore()
  const oidcStore = useOidcStore()

  let user = oidcStore.getterUser

  if (!user) {
    console.log('oidcHelper:getAccessToken no user found, start signin.')
    oidcStore.oidcSignin({ currentRoute: '/' })
  }

  console.log('oidcHelper:getAccessToken start to get access token. ', user, isSilentSignin)

  var mins = calculateExpirationTime(user)

  if (mins < 5) {
    if (isSilentSignin) return Promise.resolve(null)
    isSilentSignin = true

    console.log('getAccessToken, access token lifetime (+' + mins + ') is less than 5 mins, starting refreshing...')
    return uiStore.setGlobalLoading(true).then(() => {
      return oidcStore
        .oidcSilentSignin()
        .then((u) => {
          console.log('oidcHelper:getAccessToken, silent signin done.', u)

          return Promise.resolve(u.access_token)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
        .finally(() => {
          uiStore.setGlobalLoading(false)
          isSilentSignin = false
        })
    })
  }

  console.log('oidcHelper:getAccessToken returning access_token', user.access_token)

  return Promise.resolve(user.access_token)
}

export let hasAccess = (rolesDefinitions) => {
  if (!rolesDefinitions) {
    // no access control
    return true
  }

  const oidcStore = useOidcStore()
  let user = oidcStore.getterUser

  if (!user || !user.access_token) {
    return false
  }

  var currentRoles = null

  for (const currentRole of currentRoles) {
    for (const rolesDefinition of rolesDefinitions) {
      if (currentRole === rolesDefinition) {
        return true
      }
    }
  }

  return false
}

export const reloadMenuPermission = () => {
  const securityStore = useSecurityStore()
  console.log('reloadMenuPermission start')
  let pMenu = securityStore.generateMenus()
  let pPermission = securityStore.generatePermissions().finally(() => {
    console.log('reloadMenuPermission done')
  })
  return Promise.all([pMenu, pPermission])
}
