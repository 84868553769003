import enLocale from 'element-plus/dist/locale/en.mjs'

let en = {
  el: enLocale.el,
  common: {
    system_name: 'Security Server',
    current_locale: 'English',
    current_user: 'Current User',
    chinese: '中文',
    english: 'English',
    search: 'Search',
    cancel: 'Cancel',
    submit: 'Submit',
    confirm: 'Confirm',
    add: 'Add',
    create: 'Create',
    edit: 'Edit',
    update: 'Update',
    delete: 'Delete',
    batch_delete: 'Batch Delete',
    batch_disable: 'Batch Disable',
    remove: 'Remove',
    import: 'Import',
    export: 'Export',
    export_app_data: 'export_app_data_',
    export_user_data: 'export_user_data_',
    export_client_data: 'export_client_data_',
    export_api_resource_data: 'export_api_resource_data_',
    export_identity_resource_data: 'export_identity_resource_data_',
    export_api_scope_data: 'export_api_scope_data_',
    action: 'Action',
    delete_confirm: 'Are you sure to delete this record?',
    state_confirm: 'Are you sure to change state of this record?',
    batch_delete_confirm: 'Are you sure to batch delete these record(s)?',
    batch_disable_confirm: 'Are you sure to batch disable these record(s)?',
    prompt: 'Prompt',
    no_record: 'No record is selected.',
    create_success: 'Record has been created successfully.',
    update_success: 'Record has been updated successfully.',
    delete_success: 'Record has been deleted successfully.',
    disable_success: 'Record has been disabled or enabled successfully.',
    batch_delete_success: 'Record(s) have been deleted successfully.',
    batch_disable_success: 'Record(s) have been disabled successfully.',
    uId: 'UId',
    name: 'Name',
    display_name: 'Display Name',
    type: 'Type',
    category: 'Category',
    status: 'Status',
    provider: 'Provider',
    close: 'Close',
    enabled: 'Enabled',
    newUId: 'New uId',
    generateUId: 'Generate UId',
    description: 'Description',
    create_by: 'Created By',
    create_time: 'Created Time',
    update_by: 'Updated By',
    update_time: 'Updated Time',
    mandatory: 'Mandatory',
    format_error: 'Wront Format',
    import_prompt: 'Please drag the file here or click upload',
    import_tip: '.xlsx file only',
    import_json_tip: '.json file only',
    import_success: 'import success',
    import_failed: 'import failed',
    exist_in_upload_file: 'username exist in upload file',
    need_file_data: 'Please upload correct file',
    upload_success: 'upload success',
    upload_failed: 'upload failed',
    app_name: 'App Name',
    apps: 'Apps',
    roles: 'Roles',
    app: 'App',
    role: 'Role',
    module: 'Module',
    module_name: 'Module Name',
    please_select: 'Please Select',
    all: 'All',
    save: 'Save',
    yes: 'Yes',
    no: 'No',
    form_user: 'Form User',
    ad_user: 'AD User',
    resource_owner_password_grant: 'Resource Owner Password',
    client_credentials_grant: 'Client Credentials',
    min_length_validation: 'At least {length} characters.',
    max_length_validation: 'At most {length} characters.',
    between_length_validation: 'Between {min} and {max} characters.',
    identity: 'Identity',
    resource: 'Resource',
    start_date: 'Start Date',
    end_date: 'End Date',
    to: 'To',
    last_three_weeks: 'Last 3 Weeks',
    last_month: 'Last Month',
    last_three_months: 'Last 3 Months',
    last_week: 'Last Week',
    detail: 'Detail',
    ok: 'OK',
    system_info: 'System Info',
    os_platform: 'OS',
    runtime_version: 'Runtime Version',
    memory_usage: 'Memory Usage',
    app_version: 'System Version',
    app_build_time: 'Built On',
    app_git_commit: 'Commit Hash',
    uid: 'UId',
    select_date: 'Select Date',
    key: 'Key',
    value: 'Value',
    day_ago: 'day ago',
    days_ago: 'days ago',
    hour_ago: 'hour ago',
    hours_ago: 'hours ago',
    minute_ago: 'minute ago',
    minutes_ago: 'minutes ago',
    second_ago: 'second ago',
    seconds_ago: 'seconds ago',
    year_ago: 'year ago',
    years_ago: 'years ago',
    a_few_seconds_ago: 'a few seconds ago',
    a_few_seconds: 'a few seconds',
    in_a_few_seconds: 'in a few seconds',
    a_minute_ago: 'a minute ago',
    an_hour_ago: 'an hour ago',
    a_day_ago: 'a day ago',
    a_month_ago: 'a month ago',
    months_ago: 'months ago',
    a_year_ago: 'a year ago',
    not_json_slice: 'Not Json Slice',
    client_id: 'Client Id',
    ad_user_not_exist: 'Ad user not exist',
    please_select_file_to_upload: 'Please select file to upload',
    reset: 'Reset',
    sort_index: 'Sort Index',
    input_cannot_empty: 'The input field cannot be empty',
    common_filter: 'Common Filter',
    profile: 'Profile',
    environment: 'Environment',
    true: 'True',
    false: 'False'
  },
  enum: {
    user_status: {
      active: 'Active',
      disabled: 'Disabled',
      unconfirmed: 'Unconfirmed'
    },
    email_template: {
      user_registration: 'User Registration',
      reset_password: 'Reset Password',
      initial_password_registration: 'Initial Password Registration'
    },
    short_message_setting_type: {
      phone_number_verification: 'Phone Number Verification'
    },
    short_message_setting_provider: {
      aliyun: 'Aliyun',
      aws_sns: 'Aws SNS'
    },
    notification_type: {
      user_registration: 'User Registration',
      reset_password: 'Reset Password'
    },
    notification_category: {
      email: 'Email',
      sms: 'SMS'
    },
    notification_status: {
      success: 'Success',
      failed: 'Failed',
      unknown: 'Unknown'
    }
  },
  router: {
    home: 'Home',
    administration: 'Administration',
    user_main: 'User',
    app_main: 'App',
    application: 'Application',
    role_main: 'Role',
    module_main: 'Module',
    resource_main: 'Resource',
    operation_main: 'Operation',
    permission_main: 'Permission',
    client_main: 'Client',
    organization: 'Organization',
    oidc: 'OpenID Connect',
    api_resource_main: 'Api Resource',
    api_scope_main: 'Api Scope',
    identity_resource_main: 'Identity Resource',
    menu_group_main: 'Menu Group',
    menu_main: 'Menu',
    asset_main: 'Asset',
    system: 'System',
    system_config_main: 'System Config',
    system_log_main: 'System Log',
    refresh_token_main: 'Refresh Token',
    resource: 'Resource',
    role: 'Role',
    loading: 'Loading...',
    error: 'Error',
    access_denied: 'Access denied',
    not_found: 'Page not found.',
    privacy_policy_main: 'Privacy Policy',
    information_collection_agreement_main: 'Information Collection',
    email_template_main: 'Email Template',
    short_message_setting_main: 'SMS Setting',
    notification_main: 'Notifications',
    group_main: 'Group'
  },
  error: {
    error: 'Error',
    back_to_home: 'Back to Home',
    service_call_fail: 'Calling service failed, detail: '
  },
  signin: {
    signin: 'Sign In',
    please_signin: 'Please Sign In',
    user_name: 'User Name',
    password: 'Password',
    remember_password: 'Remember Me',
    user_name_missing: 'Please input user name.',
    password_missing: 'Please input password.',
    terms_and_conditions: 'Terms & Conditions',
    privacy_statement: 'Privacy Statement',
    signin_failed: 'Sign in failed',
    sign_in_failed_prompt: 'Sign in failed, reason: {reason}'
  },
  home: {
    my_message: 'My Mesasge',
    profile: 'Profile',
    settings: 'Settings',
    logout: 'Logout',
    logout_prompt: 'Are you sure to logout?',
    logout_caption: 'Confirmation',
    clear_cache: 'Clear Cache',
    cache_cleared_success: 'Cache was cleared successfully!',
    total_users: 'Total Users',
    all_users: 'All users',
    users: 'Users',
    total_applications: 'Total Applications',
    applications: 'Applications',
    all_applications: 'All applications',
    total_clients: 'Total Clients',
    clients: 'Clients',
    all_clients: 'All clients',
    top_active_user: 'Top Active Users',
    top_active_user_header: 'In the past week, a total of',
    top_active_user_content: 'users have logged in',
    top_active_user_footer: 'times asset.',
    monthly_login_overview: 'Monthly Login Overview',
    active_application: 'Active applications',
    active_application_desc: 'The following graphs describe the percentage of active applications per month.',
    last_login: 'Last login',
    messages: 'Messages',
    login_record: 'Login record',
    recent_logins: 'Recent Logins',
    recent_login_failures: 'Recent Login Failures',
    last_login_record: 'Last login 10 records.',
    login_in: 'Login in',
    login: 'Login',
    today_logins: 'Today Logins',
    today_login_counts: 'Today login counts',
    login_counts: 'Login Counts',
    user_counts: 'User Counts',
    recent_operations: 'Recent Operations',
    type: 'Type',
    category: 'Category',
    source: 'Source',
    operation: 'Operation',
    operator_name: 'Operator Name',
    operation_object: 'Operation Object',
    operation_time: 'Operation Time',
    operation_proportion: 'Operations Proportion',
    profile_update_success_prompt: 'Your profile has been updated successfully.',
    fullscreen: 'Fullscreen',
    exit_fullscreen: 'Exit Fullscreen',
    fullscreen_not_supported: 'Fullscreen is not supported by your browser.',
    theme: 'Theme',
    theme_default: 'Default',
    theme_dark: 'Dark'
  },
  user: {
    type: 'Type',
    user_search: 'User Search',
    form_user: 'Form User',
    ad_user: 'Ad User',
    external_user: 'External User',
    subject: 'Subject',
    status: 'Status',
    user_name: 'User Name',
    password: 'Password',
    enabled: 'Enabled',
    disabled: 'Disabled',
    family_name: 'Family Name',
    given_name: 'Given Name',
    email: 'Email',
    role: 'Role',
    last_login_time: 'Last Login Time',
    user_permission: 'User Permissions',
    change_password: 'Change Password',
    password_confirm: 'Password Confirm',
    password_not_match: 'Password Not Match',
    password_changed: 'Password Changed',
    add_item: 'Add Item',
    two_factor_enabled: '2FA Enabled',
    lockout_enabled: 'Lockout Enabled',
    all_status: 'All Status',
    all_apps: 'All Apps',
    all_roles: 'All Roles',
    lockout_end: 'Lockout End',
    access_failed_count: 'Fail Count'
  },
  group: {
    create_root_group: 'Create Root Group',
    create_subgroup: 'Create Sub Group',
    create_group: 'Create / Edit Group',
    type: 'Group Type',
    standard: 'Standard',
    add_user: 'Add User',
    add_to_group: 'Add to group'
  },
  client: {
    client_id: 'Client Id',
    client_name: 'Client Name',
    client_type: 'Client Type',
    basic: 'Basic Information',
    general: 'General Settings',
    authentication: 'Authentication Settings',
    token: 'Token Settings',
    consent: 'Consent Settings',
    device: 'Device',
    protocol_type: 'Protocol Type',
    require_client_secret: 'Require Client Secret',
    require_pkce: 'Require Pkce',
    allow_plain_text_pkce: 'Allow Plain Text Pkce',
    allow_offline_access: 'Allow Offline Access',
    allow_access_tokens_via_browser: 'Allow Browser Access Tokens',
    description: 'Description',
    add_scope: 'Add Scope',
    client_scopes: 'Client Scopes',
    add_item: 'Add Item',
    redirect_uris: 'Redirect Uris',
    duplicated_item: 'Duplicated Item',
    client_grant_types: 'Client Grant Types',
    enable_local_login: 'Enable Local Login',
    front_channel_logout_uri: 'Front Channel Logout Uri',
    front_channel_logout_session_required: 'Front Channel Logout Session Required',
    back_channel_logout_uri: 'Back Channel Logout Uri',
    back_channel_logout_session_required: 'Back Channel Logout Session Required',
    client_post_logout_redirect_uris: 'Client Post Logout Redirect Uris',
    client_idp_restrictions: 'Client Idp Restrictions',
    user_sso_lifetime: 'User SSO Lifetime',
    identity_token_lifetime: 'Identity Token Lifetime',
    access_token_lifetime: 'Access Token Lifetime',
    access_token_type: 'Access Token Type',
    authorization_code_lifetime: 'Authorization Code Lifetime',
    absolute_refresh_token_lifetime: 'Absolute Refresh Token Lifetime',
    sliding_refresh_token_lifetime: 'Sliding Refresh Token Lifetime',
    refresh_token_usage: 'Refresh Token Usage',
    refresh_token_expiration: 'Refresh Token Expiration',
    client_cors_origins: 'Client Cors Origins',
    update_access_token_claims_on_refresh: 'Update Access Token Claims On Refresh',
    include_jwt_id: 'Include Jwt Id',
    always_send_client_claims: 'Always Send Client Claims',
    always_include_user_claims_in_id_token: 'Always Include User Claims In Id Token',
    client_claims_prefix: 'Client Claims Prefix',
    pair_wise_subject_salt: 'Pair Wise Subject Salt',
    require_consent: 'Require Consent',
    allow_remember_consent: 'Allow Remember Consent',
    client_uri: 'Client Uri',
    logo_uri: 'Logo Uri',
    user_code_type: 'User Code Type',
    device_code_lifetime: 'Device Code Lifetime',
    client_secrets: 'Client Secrets',
    secret_type: 'Secret Type',
    expiration: 'Expiration',
    creation_time: 'Creation Time',
    secret_value: 'Secret Value',
    hash_type: 'Hash Type',
    no_expiration: 'No Expiration',
    client_properties: 'Client Properties',
    duplicated_key: 'Duplicated Key',
    client_claims: 'Client Claims',
    type: 'Type',
    duplicated_secret: 'Duplicated Secret.',
    duplicated_type: 'Duplicated Type',
    option: 'Option',
    custom_option: 'Custom Option',
    client_id_exist: 'Client Id Exist',
    api_resource_name_exist: 'Api Resource Name Exist',
    api_identity_resource_name_exist: 'Api Identity Resource Name Exist',
    api_scope_name_exist: 'Api Scope Name Exist'
  },
  api_resource: {
    last_accessed_time: 'Last Access Time',
    non_editable: 'Non Editable',
    api_resource_claims: 'Api Resource Claims',
    api_resource_scopes: 'Api Resource Scopes',
    api_resource_secrets: 'Api Resource Secrets',
    api_resource_properties: 'Api Resource Properties',
    add_item: 'Add Item',
    required: 'Required',
    emphasize: 'Emphasize',
    show_in_discovery_document: 'Show In Discovery Document',
    secret_type: 'Secret Type',
    expiration: 'Expiration',
    creation_time: 'Creation Time',
    secret_value: 'Secret Value',
    hash_type: 'Hash Type',
    no_expiration: 'No Expiration',
    duplicated_key: 'Duplicated Key',
    name: 'Api Resource Name'
  },
  api_scope: {
    non_editable: 'Non Editable',
    last_accessed_time: 'Last Accessed Time',
    api_scope_claims: 'Api Scope Claims',
    api_scope_properties: 'Api Scope Properties',
    required: 'Required',
    emphasize: 'Emphasize',
    show_in_discovery_document: 'Show In Discovery',
    add_item: 'Add Item',
    name: 'Api Scope Name'
  },
  identity_resource: {
    required: 'Required',
    emphasize: 'Emphasize',
    show_in_discovery_document: 'Discovery Doc',
    non_editable: 'Non Editable',
    identity_resource_claims: 'Identity Resource Claims',
    identity_resource_properties: 'Identity Resource Properties',
    add_item: 'Add Item',
    name: 'Api Identity Resource Name'
  },
  app: {
    key: 'Key'
  },
  permission: {
    permissions_saved: 'Permissions saved successfully.',
    other_resource: 'Other Resources (No Module)'
  },
  menu: {
    target: 'Target',
    sort_index: 'Sort Index',
    translation_key: 'Translation Key',
    uri: 'Uri',
    create_root: 'Create Root',
    create_sub: 'Create Sub',
    menu_group: 'Menu Group',
    move_success: 'Move Success',
    component: 'Component',
    icon: 'Icon',
    is_leaf: 'Is Leaf',
    pascal_name: 'PascalName',
    quick_fill: 'Quick Fill'
  },
  system_config: {
    key: 'Key',
    value: 'Value',
    description: 'Description'
  },
  system_log: {
    operation_time: 'Operation Time',
    operation_object: 'Operation Object',
    operation_object_id: 'Operation Object Id',
    operation: 'Operation',
    operator_ip: 'Operator Ip',
    operator_name: 'Operator Name',
    category: 'Category',
    source: 'Source',
    type: 'Type',
    id: 'Id',
    data: 'Data',
    remark: 'Remark'
  },
  asset: {
    key: 'Key',
    value: 'Value',
    physical_path: 'Physical Path',
    physical_file_name: 'Physical File Name',
    file_name: 'File Name',
    tag: 'Tag',
    asset_file: 'Asset File'
  },
  refresh_token: {
    client_id: 'Client Id',
    expires_utc: 'Expires Utc',
    issued_utc: 'Issued Utc',
    name: 'Name',
    subject: 'Subject',
    token_id: 'Token Id',
    id: 'Id'
  },
  privacy_policy: {
    version: 'Version',
    language_code: 'Language Code',
    content: 'Content'
  },
  information_collection_agreement: {
    version: 'Version',
    language_code: 'Language Code',
    content: 'Content'
  },
  email_template: {
    type: 'Type',
    language_code: 'Language Code',
    subject: 'Subject',
    content: 'Content',
    mail_cc: 'Mail Cc',
    mail_bcc: 'Mail Bcc'
  },
  short_message_setting: {
    setting: 'Setting'
  },
  notification: {
    recipient: 'Recipient',
    content: 'Content',
    response: 'Response',
    remark: 'Remark',
    data: 'Data'
  },
  json_ql: {
    all_records: 'All Records View',
    create_view: 'Create View',
    update_view: 'Update View',
    clone_view: 'Clone View',
    delete_view: 'Delete View',
    filters: 'Filters',
    cancel: 'Cancel',
    save_filter: 'Save Filter',
    add_filter: 'Add Filter',
    mandatory: 'Mandatory',
    view_detail: 'View Detail',
    name: 'Name',
    match_all_filters: 'Match all the filters with AND logic',
    match_custom_logic: 'Match filters with customized logic',
    remove_all_filter_prompt: 'Remove All Filter Prompt',
    confirm: 'Confirm',
    remove_all: 'Remove All',
    filter_logic: 'Filter Logic',
    add_filter_logic: 'Add Filter Logic',
    remove_filter_logic: 'Remove Filter Logic',
    delete_confirm: 'Are you sure to delete?',
    delete_prompt: 'Confirmation',
    field: 'Field',
    operator: 'Operator',
    value: 'Value',
    eq: 'equals',
    neq: 'not equal to',
    exist: 'exists',
    prefix: 'start with',
    suffix: 'end with',
    like: 'contains',
    nlike: 'not contain',
    in: 'in',
    nin: 'not in',
    gt: 'greater than',
    lt: 'less than',
    gte: 'greater or equal',
    lte: 'less or equal',
    done: 'Done',
    select_date_time: 'Select date time',
    search_by_query: 'Search by query'
  },
  profile: {
    given_name: 'Given Name',
    family_name: 'Family Name',
    avatar: 'Avatar',
    user_name: 'Username',
    email: 'Email',
    phone: 'Phone Number'
  },
  message: 'hello',
  ...enLocale // Or use `Object.assign({ message: 'hello' }, enLocale)`
}

export default en
