import * as util from '@/common/util'

const globalFilter = {
  formatDate(value) {
    return util.formatDate.format(new Date(value), 'yyyy-MM-dd')
  },
  formatDateTime(value) {
    return util.formatDate.format(new Date(value), 'yyyy-MM-dd hh:mm')
  },
  formatDateTimeSeconds(value) {
    return util.formatDate.format(new Date(value), 'yyyy-MM-dd hh:mm:ss')
  },
  formatDateTimeLong(value) {
    var times = value.split('.')
    var microseconds = times[1]
    microseconds = microseconds.replace('Z', '')
    var shortLength = 4 - microseconds.length
    for (var i = 0; i < shortLength; i++) {
      microseconds += '0'
    }
    microseconds = microseconds.substring(0, 4)
    let d = new Date(value)
    return util.formatDate.format(d, 'yyyy-MM-dd hh:mm:ss') + ' ' + microseconds
  }
}

export default globalFilter
