import { useSecurityStore } from '@/stores/securityStore'

let hasMenuRecursive = (routeName, menu) => {
  if (routeName === menu.name) return true

  if (menu.menus && menu.menus.length) {
    for (let subMenu of menu.menus) {
      let result = hasMenuRecursive(routeName, subMenu)
      if (result) return true
    }
  }

  return false
}

export let hasMenuAccess = (route) => {
  if (route.meta && !route.meta.protected) return true

  const securityStore = useSecurityStore()
  let menus = securityStore.getterMenus
  if (!menus) return false

  for (let menu of menus) {
    let result = hasMenuRecursive(route.name, menu)
    if (result) return true
  }

  return false
}

export let hasPermission = (resource, operation) => {
  const securityStore = useSecurityStore()
  let permissions = securityStore.getterPermissions
  if (!permissions) return false
  for (let permission of permissions) {
    if (permission.resourceName === resource && permission.operationName === operation) {
      return true
    }
  }
  return false
}

export let hasAnyPermission = () => {
  const securityStore = useSecurityStore()
  let permissions = securityStore.getterPermissions
  let menus = securityStore.getterMenus

  return permissions && permissions.length > 0 && menus && menus.length > 0
}
