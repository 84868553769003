import zhLocale from 'element-plus/dist/locale/zh-cn.mjs'

let zh = {
  el: zhLocale.el,
  common: {
    system_name: '安全服务器',
    current_locale: '中文',
    current_user: '当前用户',
    chinese: '中文',
    english: 'English',
    search: '搜索',
    cancel: '取消',
    submit: '提交',
    confirm: '确认',
    add: '添加',
    create: '创建',
    edit: '编辑',
    update: '更新',
    delete: '删除',
    batch_delete: '批量删除',
    batch_disable: '批量禁用',
    remove: '移除',
    import: '导入',
    export: '导出',
    export_app_data: '导出应用数据_',
    export_user_data: '导出用户数据_',
    export_client_data: '导出客户端数据_',
    export_api_resource_data: '导出API资源数据_',
    export_identity_resource_data: '导出身份资源数据_',
    export_api_scope_data: '导出API范围数据_',
    action: '操作',
    delete_confirm: '您确定要删除这条记录吗？',
    state_confirm: '您确定要更改此记录的状态吗？',
    batch_delete_confirm: '您确定要批量删除这些记录吗？',
    batch_disable_confirm: '您确定要批量禁用这些记录吗？',
    prompt: '提示',
    no_record: '没有选中记录。',
    create_success: '记录创建成功。',
    update_success: '记录更新成功。',
    delete_success: '记录删除成功。',
    disable_success: '记录禁用或启用成功。',
    batch_delete_success: '记录批量删除成功。',
    batch_disable_success: '记录批量禁用成功。',
    uId: '唯一标识符',
    name: '名称',
    display_name: '显示名称',
    type: '类型',
    category: '类别',
    status: '状态',
    provider: '提供者',
    close: '关闭',
    enabled: '已启用',
    newUId: '新唯一标识符',
    generateUId: '生成唯一标识符',
    description: '描述',
    create_by: '创建者',
    create_time: '创建时间',
    update_by: '更新者',
    update_time: '更新时间',
    mandatory: '必填',
    format_error: '格式错误',
    import_prompt: '请将文件拖到这里或点击上传',
    import_tip: '仅支持.xlsx文件',
    import_json_tip: '仅支持.json文件',
    import_success: '导入成功',
    import_failed: '导入失败',
    exist_in_upload_file: '上传文件中存在用户名',
    need_file_data: '请上传正确的文件',
    upload_success: '上传成功',
    upload_failed: '上传失败',
    app_name: '应用名称',
    apps: '应用',
    roles: '角色',
    app: '应用',
    role: '角色',
    module: '模块',
    module_name: '模块名称',
    please_select: '请选择',
    all: '全部',
    save: '保存',
    yes: '是',
    no: '否',
    form_user: '表单用户',
    ad_user: 'AD用户',
    resource_owner_password_grant: '资源所有者密码',
    client_credentials_grant: '客户端凭据',
    min_length_validation: '至少 {length} 个字符。',
    max_length_validation: '最多 {length} 个字符。',
    between_length_validation: '字符长度介于 {min} 到 {max} 之间。',
    identity: '身份',
    resource: '资源',
    start_date: '开始日期',
    end_date: '结束日期',
    to: '到',
    last_three_weeks: '过去三周',
    last_month: '上个月',
    last_three_months: '过去三个月',
    last_week: '上周',
    detail: '详情',
    ok: '确定',
    system_info: '系统信息',
    os_platform: '操作系统',
    runtime_version: '运行版本',
    memory_usage: '内存使用',
    app_version: '系统版本',
    app_build_time: '构建时间',
    app_git_commit: '提交哈希',
    uid: '唯一标识符',
    select_date: '选择日期',
    key: '键',
    value: '值',
    day_ago: '天前',
    days_ago: '天前',
    hour_ago: '小时前',
    hours_ago: '小时前',
    minute_ago: '分钟前',
    minutes_ago: '分钟前',
    second_ago: '秒前',
    seconds_ago: '秒前',
    year_ago: '年前',
    years_ago: '年前',
    a_few_seconds_ago: '几秒前',
    a_few_seconds: '几秒',
    in_a_few_seconds: '几秒后',
    a_minute_ago: '一分钟前',
    an_hour_ago: '一小时前',
    a_day_ago: '一天前',
    a_month_ago: '一个月前',
    months_ago: '几个月前',
    a_year_ago: '一年前',
    not_json_slice: '不是JSON片段',
    client_id: '客户端ID',
    ad_user_not_exist: 'AD用户不存在',
    please_select_file_to_upload: '请选择要上传的文件',
    reset: '重置',
    sort_index: '排序索引',
    input_cannot_empty: '输入字段不能为空',
    common_filter: '常用筛选',
    profile: '个人资料',
    environment: '环境',
    true: '真',
    false: '假'
  },
  enum: {
    user_status: {
      active: '激活',
      disabled: '禁用',
      unconfirmed: '未确认'
    },
    email_template: {
      user_registration: '用户注册',
      reset_password: '重置密码',
      initial_password_registration: '初始密码注册'
    },
    short_message_setting_type: {
      phone_number_verification: '手机号验证'
    },
    short_message_setting_provider: {
      aliyun: '阿里云',
      aws_sns: '亚马逊SNS'
    },
    notification_type: {
      user_registration: '用户注册',
      reset_password: '重置密码'
    },
    notification_category: {
      email: '电子邮件',
      sms: '短信'
    },
    notification_status: {
      success: '成功',
      failed: '失败',
      unknown: '未知'
    }
  },
  router: {
    home: '首页',
    administration: '管理',
    user_main: '用户',
    app_main: '应用',
    application: '应用程序',
    role_main: '角色',
    module_main: '模块',
    resource_main: '资源',
    operation_main: '操作',
    permission_main: '权限',
    client_main: '客户端',
    organization: '组织',
    oidc: '开放ID连接',
    api_resource_main: 'API资源',
    api_scope_main: 'API范围',
    identity_resource_main: '身份资源',
    menu_group_main: '菜单组',
    menu_main: '菜单',
    asset_main: '资产',
    system: '系统',
    system_config_main: '系统配置',
    system_log_main: '系统日志',
    refresh_token_main: '刷新令牌',
    resource: '资源',
    role: '角色',
    loading: '加载中...',
    error: '错误',
    access_denied: '拒绝访问',
    not_found: '页面未找到。',
    privacy_policy_main: '隐私政策',
    information_collection_agreement_main: '信息收集协议',
    email_template_main: '电子邮件模板',
    short_message_setting_main: '短信设置',
    notification_main: '通知',
    group_main: '组'
  },
  error: {
    error: '错误',
    back_to_home: '返回首页',
    service_call_fail: '调用服务失败，详情：'
  },
  signin: {
    signin: '登录',
    please_signin: '请登录',
    user_name: '用户名',
    password: '密码',
    remember_password: '记住密码',
    user_name_missing: '请输入用户名。',
    password_missing: '请输入密码。',
    terms_and_conditions: '条款和条件',
    privacy_statement: '隐私声明',
    signin_failed: '登录失败',
    sign_in_failed_prompt: '登录失败，原因：{reason}'
  },
  home: {
    my_message: '我的消息',
    profile: '个人资料',
    settings: '设置',
    logout: '退出登录',
    logout_prompt: '您确定要退出吗？',
    logout_caption: '确认',
    clear_cache: '清除缓存',
    cache_cleared_success: '缓存已成功清除！',
    total_users: '总用户数',
    all_users: '所有用户',
    users: '用户',
    total_applications: '总应用数',
    applications: '应用',
    all_applications: '所有应用',
    total_clients: '总客户端数',
    clients: '客户端',
    all_clients: '所有客户端',
    top_active_user: '活跃用户排行',
    top_active_user_header: '在过去的一周内，总共有',
    top_active_user_content: '用户登录了',
    top_active_user_footer: '次资产。',
    monthly_login_overview: '月登录概览',
    active_application: '活跃应用',
    active_application_desc: '以下图表描述了每月活跃应用的百分比。',
    last_login: '最后登录',
    messages: '消息',
    login_record: '登录记录',
    recent_logins: '最近登录',
    recent_login_failures: '最近登录失败',
    last_login_record: '最近10次登录记录。',
    login_in: '正在登录',
    login: '登录',
    today_logins: '今日登录',
    today_login_counts: '今日登录次数',
    login_counts: '登录次数',
    user_counts: '用户数量',
    recent_operations: '最近操作',
    type: '类型',
    category: '类别',
    source: '来源',
    operation: '操作',
    operator_name: '操作员姓名',
    operation_object: '操作对象',
    operation_time: '操作时间',
    operation_proportion: '操作比例',
    profile_update_success_prompt: '您的个人资料已成功更新。',
    fullscreen: '全屏',
    exit_fullscreen: '退出全屏',
    fullscreen_not_supported: '您的浏览器不支持全屏。',
    theme: '主题',
    theme_default: '默认',
    theme_dark: '黑暗'
  },
  user: {
    type: '类型',
    user_search: '用户搜索',
    form_user: '表单用户',
    ad_user: 'AD用户',
    external_user: '外部用户',
    subject: '主题',
    status: '状态',
    user_name: '用户名',
    password: '密码',
    enabled: '已启用',
    disabled: '已禁用',
    family_name: '姓氏',
    given_name: '名字',
    email: '邮箱',
    role: '角色',
    last_login_time: '上次登录时间',
    user_permission: '用户权限',
    change_password: '更改密码',
    password_confirm: '确认密码',
    password_not_match: '密码不匹配',
    password_changed: '密码已更改',
    add_item: '添加项目',
    two_factor_enabled: '启用双因素认证',
    lockout_enabled: '启用锁定',
    all_status: '所有状态',
    all_apps: '所有应用',
    all_roles: '所有角色',
    lockout_end: '锁定结束',
    access_failed_count: '失败计数'
  },
  group: {
    create_root_group: '创建根组',
    create_subgroup: '创建子组',
    create_group: '创建/编辑组',
    type: '组类型',
    standard: '标准',
    add_user: '添加用户',
    add_to_group: '添加到组'
  },
  client: {
    client_id: '客户端ID',
    client_name: '客户端名称',
    client_type: '客户端类型',
    basic: '基本信息',
    general: '常规设置',
    authentication: '认证设置',
    token: '令牌设置',
    consent: '同意设置',
    device: '设备',
    protocol_type: '协议类型',
    require_client_secret: '需要客户端密钥',
    require_pkce: '需要PKCE',
    allow_plain_text_pkce: '允许明文PKCE',
    allow_offline_access: '允许离线访问',
    allow_access_tokens_via_browser: '允许浏览器访问令牌',
    description: '描述',
    add_scope: '添加范围',
    client_scopes: '客户端范围',
    add_item: '添加项目',
    redirect_uris: '重定向URIs',
    duplicated_item: '重复项',
    client_grant_types: '客户端授权类型',
    enable_local_login: '启用本地登录',
    front_channel_logout_uri: '前端通道登出URI',
    front_channel_logout_session_required: '需要前端通道登出会话',
    back_channel_logout_uri: '后端通道登出URI',
    back_channel_logout_session_required: '需要后端通道登出会话',
    client_post_logout_redirect_uris: '客户端登出后重定向URIs',
    client_idp_restrictions: '客户端Idp限制',
    user_sso_lifetime: '用户SSO生命周期',
    identity_token_lifetime: '身份令牌生命周期',
    access_token_lifetime: '访问令牌生命周期',
    access_token_type: '访问令牌类型',
    authorization_code_lifetime: '授权码生命周期',
    absolute_refresh_token_lifetime: '绝对刷新令牌生命周期',
    sliding_refresh_token_lifetime: '滑动刷新令牌生命周期',
    refresh_token_usage: '刷新令牌使用',
    refresh_token_expiration: '刷新令牌过期',
    client_cors_origins: '客户端CORS来源',
    update_access_token_claims_on_refresh: '刷新时更新访问令牌声明',
    include_jwt_id: '包含JWT ID',
    always_send_client_claims: '始终发送客户端声明',
    always_include_user_claims_in_id_token: '始终将用户声明包含在身份令牌中',
    client_claims_prefix: '客户端声明前缀',
    pair_wise_subject_salt: '成对主题盐',
    require_consent: '需要同意',
    allow_remember_consent: '允许记住同意',
    client_uri: '客户端URI',
    logo_uri: 'Logo URI',
    user_code_type: '用户代码类型',
    device_code_lifetime: '设备代码生命周期',
    client_secrets: '客户端密钥',
    secret_type: '密钥类型',
    expiration: '过期',
    creation_time: '创建时间',
    secret_value: '密钥值',
    hash_type: '哈希类型',
    no_expiration: '不过期',
    client_properties: '客户端属性',
    duplicated_key: '重复键',
    client_claims: '客户端声明',
    type: '类型',
    duplicated_secret: '重复密钥。',
    duplicated_type: '重复类型',
    option: '选项',
    custom_option: '自定义选项',
    client_id_exist: '客户端ID已存在',
    api_resource_name_exist: 'API资源名称已存在',
    api_identity_resource_name_exist: 'API身份资源名称已存在',
    api_scope_name_exist: 'API范围名称已存在'
  },
  api_resource: {
    last_accessed_time: '上次访问时间',
    non_editable: '不可编辑',
    api_resource_claims: 'API资源声明',
    api_resource_scopes: 'API资源范围',
    api_resource_secrets: 'API资源密钥',
    api_resource_properties: 'API资源属性',
    add_item: '添加项目',
    required: '必需',
    emphasize: '强调',
    show_in_discovery_document: '在发现文档中显示',
    secret_type: '密钥类型',
    expiration: '过期',
    creation_time: '创建时间',
    secret_value: '密钥值',
    hash_type: '哈希类型',
    no_expiration: '不过期',
    duplicated_key: '重复键',
    name: 'API资源名称'
  },
  api_scope: {
    non_editable: '不可编辑',
    last_accessed_time: '上次访问时间',
    api_scope_claims: 'API范围声明',
    api_scope_properties: 'API范围属性',
    required: '必需',
    emphasize: '强调',
    show_in_discovery_document: '在发现文档中显示',
    add_item: '添加项目',
    name: 'API范围名称'
  },
  identity_resource: {
    required: '必需',
    emphasize: '强调',
    show_in_discovery_document: '在发现文档中显示',
    non_editable: '不可编辑',
    identity_resource_claims: '身份资源声明',
    identity_resource_properties: '身份资源属性',
    add_item: '添加项目',
    name: '身份资源名称'
  },
  app: {
    key: '键'
  },
  permission: {
    permissions_saved: '权限保存成功。',
    other_resource: '其他资源（无模块）'
  },
  menu: {
    target: '目标',
    sort_index: '排序索引',
    translation_key: '翻译键',
    uri: 'URI',
    create_root: '创建根菜单',
    create_sub: '创建子菜单',
    menu_group: '菜单组',
    move_success: '移动成功',
    component: '组件',
    icon: '图标',
    is_leaf: '是叶子节点',
    pascal_name: 'PascalName',
    quick_fill: '快速填充'
  },
  system_config: {
    key: '键',
    value: '值',
    description: '描述'
  },
  system_log: {
    operation_time: '操作时间',
    operation_object: '操作对象',
    operation_object_id: '操作对象ID',
    operation: '操作',
    operator_ip: '操作员IP',
    operator_name: '操作员姓名',
    category: '类别',
    source: '来源',
    type: '类型',
    id: 'ID',
    data: '数据',
    remark: '备注'
  },
  asset: {
    key: '键',
    value: '值',
    physical_path: '物理路径',
    physical_file_name: '物理文件名',
    file_name: '文件名',
    tag: '标签',
    asset_file: '资产文件'
  },
  refresh_token: {
    client_id: '客户端ID',
    expires_utc: '过期时间（UTC）',
    issued_utc: '发行时间（UTC）',
    name: '名称',
    subject: '主题',
    token_id: '令牌ID',
    id: 'ID'
  },
  privacy_policy: {
    version: '版本',
    language_code: '语言代码',
    content: '内容'
  },
  information_collection_agreement: {
    version: '版本',
    language_code: '语言代码',
    content: '内容'
  },
  email_template: {
    type: '类型',
    language_code: '语言代码',
    subject: '主题',
    content: '内容',
    mail_cc: '抄送',
    mail_bcc: '密送'
  },
  short_message_setting: {
    setting: '设置'
  },
  notification: {
    recipient: '接收人',
    content: '内容',
    response: '响应',
    remark: '备注',
    data: '数据'
  },
  json_ql: {
    all_records: '所有记录视图',
    create_view: '创建视图',
    update_view: '更新视图',
    clone_view: '克隆视图',
    delete_view: '删除视图',
    filters: '筛选器',
    cancel: '取消',
    save_filter: '保存筛选器',
    add_filter: '添加筛选器',
    mandatory: '必填',
    view_detail: '查看详情',
    name: '名称',
    match_all_filters: '与所有筛选器匹配（AND逻辑）',
    match_custom_logic: '与自定义逻辑匹配筛选器',
    remove_all_filter_prompt: '提示：移除所有筛选器',
    confirm: '确认',
    remove_all: '移除所有',
    filter_logic: '筛选器逻辑',
    add_filter_logic: '添加筛选器逻辑',
    remove_filter_logic: '移除筛选器逻辑',
    delete_confirm: '您确定要删除吗？',
    delete_prompt: '确认',
    field: '字段',
    operator: '操作符',
    value: '值',
    eq: '等于',
    neq: '不等于',
    exist: '存在',
    prefix: '前缀',
    suffix: '后缀',
    like: '包含',
    nlike: '不包含',
    in: '在',
    nin: '不在',
    gt: '大于',
    lt: '小于',
    gte: '大于等于',
    lte: '小于等于',
    done: '完成',
    select_date_time: '选择日期时间',
    search_by_query: '按查询搜索'
  },
  profile: {
    given_name: '名字',
    family_name: '姓氏',
    avatar: '头像',
    user_name: '用户名',
    email: '电子邮箱',
    phone: '电话号码'
  },
  message: '你好',
  ...zhLocale
}

export default zh
