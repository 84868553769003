import { defineStore } from 'pinia'

export const useConfigStore = defineStore({
  id: 'configStore',
  persist: {
    key: import.meta.env.VITE_LOCAL_STORAGE_PREFIX + 'configStore',
    storage: localStorage
  },
  state: () => ({
    currentConfig: null
  }),
  getters: {
    getterCurrentConfig: (state) => {
      return state.currentConfig
    }
  },
  actions: {
    setCurrentConfig(payload) {
      this.currentConfig = payload
      return Promise.resolve()
    }
  }
})
