import * as securityHelper from '@/common/securityHelper'

const PermissionDirective = {
  mounted(el, binding, vnode) { // eslint-disable-line
    let value = binding.value

    const hasPermission = securityHelper.hasPermission(value.r, value.o)
    //console.log('permission check', binding.value, hasPermission, el, el.parentNode)
    if (!hasPermission) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
}

export default PermissionDirective
