import { useConfigStore } from '@/stores/configStore'

export const getConfig = () => {
  const configStore = useConfigStore()
  return {
    baseUrl: configStore.getterCurrentConfig.baseUrl,
    apiBaseUrl: configStore.getterCurrentConfig.apiBaseUrl,
    authMode: configStore.getterCurrentConfig.authMode,
    oidc: configStore.getterCurrentConfig.oidc,
    companyName: configStore.getterCurrentConfig.companyName,
    enableThemeSelection: configStore.getterCurrentConfig.enableThemeSelection,
    defaultTheme: configStore.getterCurrentConfig.defaultTheme,
    faviconUrl: configStore.getterCurrentConfig.faviconUrl,
    logoMainUrl: configStore.getterCurrentConfig.logoMainUrl,
    logoSmallUrl: configStore.getterCurrentConfig.logoSmallUrl
  }
}
