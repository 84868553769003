import { defineStore } from 'pinia'
import { getConfig } from '@/common/config'
import { UserManager } from 'oidc-client'
import lang from '@/lang'

let userManager = null

const createUserManager = () => {
  const oidcConfig = getConfig().oidc

  if (userManager) {
    console.log('oidcConfig:userManager exists, skipping creation.')
    return userManager
  }

  console.log('oidcConfig:createUserManager', JSON.stringify(oidcConfig))

  userManager = new UserManager(oidcConfig)
  return userManager
}

const cleanLocalStorage = () => {
  Object.keys(localStorage)
    .filter((x) => x.startsWith('oidc.'))
    .forEach((x) => localStorage.removeItem(x))
}

export const useOidcStore = defineStore({
  id: 'oidcStore',
  persist: {
    key: import.meta.env.VITE_LOCAL_STORAGE_PREFIX + 'oidcStore',
    storage: localStorage
  },
  state: () => ({
    user: null
  }),
  getters: {
    getterUser: (state) => {
      return state.user
    }
  },
  actions: {
    oidcSignin(payload) {
      console.log('oidc:oidcSignin start', payload)

      cleanLocalStorage()

      let userManager = createUserManager()

      return userManager
        .signinRedirect({
          data: { currentRoute: payload.currentRoute },
          extraQueryParams: { ui_locales: lang.getLocale() }
        })
        .catch((err) => {
          console.log('oidc:oidcSignin error!', err)
          return Promise.reject(err)
        })
    },
    oidcCallback() {
      console.log('oidc:oidcCallback start')
      let userManager = createUserManager()
      return userManager
        .signinRedirectCallback()
        .then((user) => {
          console.log('oidc:oidcCallback signin response success', user)

          this.user = user

          return user
        })
        .catch((err) => {
          console.log('oidc:oidcCallback error', err)
        })
    },
    oidcSilentSignin() {
      console.log('oidc:oidcSilentSignin')
      let userManager = createUserManager()
      return userManager
        .signinSilent()
        .then((user) => {
          console.log('oidc:oidcSilentSignin then', user)
          this.user = user
          return user
        })
        .catch((reason) => {
          console.log('oidc:oidcSilentSignin reason', reason)
          //if silent signin error, let user do signin again
          if (reason.error == 'login_required') {
            const oidcStore = useOidcStore()
            oidcStore.oidcSignin({ currentRoute: '/' })
          }
          return Promise.reject(reason)
        })
    },
    oidcSilentCallback() {
      console.log('oidc:oidcSilentCallback')
      let userManager = createUserManager()
      return userManager.signinSilentCallback()
        
    },
    oidcSignout() {
      console.log('oidc:oidcSignout start')

      cleanLocalStorage()

      this.user = null

      let userManager = createUserManager()

      return userManager.signoutRedirect({
        extraQueryParams: { ui_locales: lang.getLocale() }
      })
    }
  }
})
