<template>
  <el-config-provider :locale="currentLocale">
    <div>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </el-config-provider>
</template>

<script>
import { computed } from 'vue'
import { ElConfigProvider } from 'element-plus'
import en from 'element-plus/dist/locale/en.mjs'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import lang from '@/lang'
export default {
  name: 'App',
  components: { ElConfigProvider },
  setup() {
    const computedData = {
      currentLocale: computed(() => {
        let localeName = lang.getLocale()
        if (localeName == 'zh-cn') return zhCn
        else if (localeName == 'en') return en
        else return en
      })
    }

    return {
      ...computedData
    }
  }
}
</script>

<style lang="scss"></style>
